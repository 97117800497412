




















































  import {Vue, Component, Prop} from 'vue-property-decorator';
  import CusTable from "@/components/table";
  import uploadModel from "./components/uploadModel.vue";
  import MixinEdu from "@/views/education/Mixins/mixin-edu";
  import $ from '../../utils/$/index'

  @Component({
    name: "paper",
    components: {uploadModel},
    mixins: [MixinEdu]
  })
  export default class extends Vue {
    private tableParam = {
      subjectCode: '',
	    type: ''
    }
    private subjectOptions = []
    $refs!: { table: CusTable, uploadModel: uploadModel };

    private openModel(data:{[key: string]: any} = {}) {
      this.$modal.create({
        component: uploadModel,
        title: data.id ? '编辑模板' : '新增模板',
        width: 550,
	      props: {data}
      }).$on('close', () => {
        this.$refs.table.request();
      })
    };
	// private update(preview: boolean, data: any) {
	// 	window.open(`./#/test-paper-edit/${preview}/${data.id}`);
	// }
	private openView(data: any) {
		// $.element('iframe')
		// const loading = ElLoading.service({ lock: true, background: 'rgba(255, 255, 255, .7)', text: '加载中...' })
		// if(!data.templatePath) return this.update(true, data)
        let src = `http://web365.xiaohe.com:8088?furl=${process.env.VUE_APP_BASE_URL}${data.templatePath}`;
        let closeBtn = $.element('div', {
          className: 'el-icon-close',
          style: { width: '36px', height: '36px', lineHeight: '36px', textAlign: 'center', background: '#fff', borderRadius: '50%', fontSize: '24px', position: 'fixed', top: '40px', right: '40px', zIndex: '10', cursor: 'pointer' },
          on: { click: () => { container.remove(); } }
        });
        let iframe = $.element('iframe', { attrs: { src, width: '99%', height: '99%' }, style: { background: '#f9f9f9' } });
        // iframe.onload = loading.close;
        let container = $.element('div', {
          style: { width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', zIndex: '9' },
        }, [ closeBtn, iframe ])
        document.body.appendChild(container);
	}
    private subjectData() {
      this.axios.post('/system/dictionary/queryAll', {typeCode: 'SUBJECT'}).then((res: any) => {
        if (res.json && res.json.length) this.subjectOptions = res.json;
      })
    };

    handleDelete(idx, row) {
      this.selectedArr = [ row ];
      this.batchDeleteHandle(this.$refs.table, '/system/paperTemplate/batchDelete');
    }

    private handleChangeStatus(idx, row) {
      this.selectedArr = [ row ];
			this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table, '/system/paperTemplate/batchChangeStatus')
    }

    private batchChangeStatusHandle(status) {
      this.batchChangeStatus(status, this.$refs.table, '/system/paperTemplate/batchChangeStatus')
    }

    protected created() {
      this.subjectData();
    }
  }
