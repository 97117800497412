











































	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { Upload } from "element-ui";

  @Component({
		name: "uploadModel"
	})
	export default class uploadModel extends Vue{
    @Prop({default: () => ({})}) data!: any;
    constructor(props) {
      super(props);
      this.data.id && Object.keys(this.param).map(i => this.param[i] = this.data[i]);
      this.subjectCode = this.data.subjectCode ? [ this.data.subjectCode as never ] : [];
			this.data.templatePath && this.fileList.push(this.data.templatePath);
      this.data.type ? this.uploadType = this.data.type : false
    }
		private uploadType = 1
	  private action = process.env.VUE_APP_BASE_URL
	  private accept = ['.docx', '.doc']
	  private subjectOptions = []
	  private subjectCode:string[] = []
    private fileList: string[] = [];
	  private param = {
			id: null,
      type: 1,
		  name: '',
      subjects: [],
      templatePath: ''
	  }
		$refs!: {upload: Upload}
    submitUpload() {
      this.$refs.upload.submit();
    };
    change(file, fileList) {
      this.param.name = file.name;
    }
    uploadSuccess(response, file, fileList) {
	    if (response.result) {
	      if (!this.param.name) this.param.name = response.json.oriFilename;
	      this.param.templatePath = response.json.filePath;
				this.fileList = [this.param.templatePath]
      }
    };
    private save() {
			if (!this.param.name) {
				this.$message.warning('请输入模板名称！');
				return;
			} else if (!this.fileList.length) {
				this.$message.warning('请选取模板文件');
				return;
			}
			this.param.type = this.uploadType;
			this.subjectOptions.map((v: any, i) => {
				this.subjectCode.map((j, n) => {
					if (v.code == j) {
						let val = {code: v.code, name: v.name} as never;
						this.param.subjects ? this.param.subjects.push(val) : (this.param.subjects = [val])
					};
				});
			});
			this.param.id = this.data.id;
			this.axios.post('/system/paperTemplate/saveOrUpdate', this.param, { headers: {'Content-Type': 'application/json'} }).then((res: any) => {
				if (res.result) {
					this.$message.success('添加成功');
					this.$emit('close');
				}
			})
    }
    private subjectData() {
			this.axios.post('/system/dictionary/queryAll', {typeCode: 'SUBJECT'}).then((res: any) => {if (res.json && res.json.length) this.subjectOptions = res.json;})
    }
		private removeFileHandle() {
			this.fileList = [];
		}

    protected created() {
      this.subjectData()
    }
	}
